import type { Element, Root } from "hast";

import { fragmentToJsx } from "~/utils/fragmentToJsx";

export function collectHeadingLinks(headings: Root[]) {
  const navs = structuredClone(headings)
    .map((heading) => {
      if (
        heading.children.length < 2 ||
        (heading.children[0] as Element).tagName !== "nav"
      ) {
        return;
      }

      return heading.children.shift() as Element;
    })
    .filter(Boolean);

  if (navs.length === 0) {
    return null;
  }

  // biome-ignore lint/style/noNonNullAssertion: <explanation>
  const result = navs.shift()!;

  for (const nav of navs) {
    (result.children[1] as Element).children.push(
      (nav?.children[1] as Element).children[0],
    );
  }

  return fragmentToJsx(result);
}
